import {v2Request} from "./v2Request";


export const goodsLectureV2Api = {

  async findByGoodsId(goodsId) {
    return v2Request.get(`goodsLecture/findByGoodsId`, {params: {goodsId}})
  },


  async findLectureUrlById(id) {
    return v2Request.get(`goodsLecture/findLectureUrlById`, {params: {id}})
  },

}
