<template>
  <div class="trial-icon" >试看</div>
</template>


------------------ script ------------------
<script>
export default {

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.trial-icon {
  background: #ee4a50;
  color: #fff;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  font-size: 12px;
  transform: scale(.8);
  padding: 4px 8px;
  margin-left: 8px;
}
</style>
