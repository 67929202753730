<template>
  <div class="goods" ref="goods">
    <v-header/>
    <van-swipe indicator-color="white">
      <van-swipe-item v-for="(item,index) in imgArr">
        <div class="img-container mosaic" @click="preview(index)">
          <van-image :src="item" v-lazy="item" fit="contain"/>
        </div>
      </van-swipe-item>
    </van-swipe>

    <div class="info">
      <div class="goods-title">{{ goods.title }}</div>
      <div class="goods-desc">{{ goods.desc }}</div>
    </div>

    <div class="goods-tab">
      <div class="goods-tab-item" @click="scrollTo('goods-detail',32)">商品详情</div>
      <div class="goods-tab-item" v-if="goods.type === goodsTypeEnum.COURSE" @click="scrollTo('lecture-list',32)">课程目录
      </div>
    </div>
    <div class="goods-tab-holder"></div>

    <div class="ql-snow goods-detail" ref="detail">
      <div class="ql-editor" v-html="goods.detail"></div>
    </div>

    <ul class="lecture-list" v-if="goods.type === goodsTypeEnum.COURSE">
      <li class="lecture-item" v-for="item in lectures" :key="item.id">
        <div class="lecture-title">
          <router-link :to="`/shop/play/${goods.id}?lectureId=${item.id}`">
            {{ item.title }}
          </router-link>
        </div>
        <div class="lecture-desc">
          {{ secondsToDayInfo(item.seconds) }}
        </div>
        <trial-icon v-if="item.isTrial"/>
      </li>
    </ul>

    <div class="pay-line" v-if="!hasBuy || goods.type !==goodsTypeEnum.COURSE">
      <div class="red">¥99</div>
      <div class="buy-btn" @click="goBuy">立即购买</div>
    </div>
    <div class="pay-line" v-if="hasBuy && goods.type===goodsTypeEnum.COURSE">
      <div> </div>
      <div class="buy-btn" @click="$router.push(`/shop/play/${goods.id}`)">去上课</div>
    </div>
  </div>
</template>


------------------ script ------------------
<script>
import {goodsV2Api} from "../../api/v2/goodsV2Api";
import {ImagePreview} from "vant";
import {getElementPos} from "../../config/util";
import {goodsTypeEnum} from "../../config/enums";
import {goodsLectureV2Api} from "../../api/v2/goodsLectureV2Api";
import TrialIcon from "../../components/shop/trialIcon";
import {mapGetters} from "vuex";
import {orderV2Api} from "../../api/v2/orderV2Api";

export default {
  components: {TrialIcon},

  data() {
    return {
      goodsTypeEnum,
      hasBuy: false,
      lectures: [],
      goods: {
        id: '',
        title: '',
        desc: '',
        detail: '',
        price: '',
        cover: '',
        imgs: '',
        type: '',
      }
    }
  },

  async created() {
    await this.loadGoods();
    this.loadLectures();
    this.hasBuy = await orderV2Api.findHasBuyGoods(this.goods.id)
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  computed: {
    imgArr() {
      const json = this.goods.imgs || '[]';
      return JSON.parse(json);
    },

    ...mapGetters(['userInfo'])
  },

  methods: {
    onScroll() {
      const detailRect = this.$refs.detail.getBoundingClientRect()
      if (detailRect.top < 98) {
        this.$refs.goods.classList.add('tab-fixed')
      } else {
        this.$refs.goods.classList.remove('tab-fixed')
      }
    },

    scrollTo(className, offset = 0) {
      const headerContainer = document.querySelector('header');
      const pos = getElementPos(document.querySelector(`.${className}`))
      window.scrollTo({top: pos.y - headerContainer.clientHeight - offset, behavior: "smooth"})
    },

    async loadGoods() {
      this.goods = await goodsV2Api.findById(this.$route.params.id);
    },

    preview(index) {
      ImagePreview({
        images: this.imgArr,
        startPosition: index
      })
    },


    async loadLectures() {
      if (this.goods.type === goodsTypeEnum.COURSE) {
        this.lectures = await goodsLectureV2Api.findByGoodsId(this.goods.id)
      }
    },


    goBuy() {
      if (!this.userInfo || !Object.keys(this.userInfo).length) {
        return this.$store.commit('SHOW_LOGIN_DIALOG');
      }
      this.$router.push(`/shop/createOrder/${this.$route.params.id}`)
    }

  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>

.goods {
  padding-top: 40px;
  padding-bottom: 50px;
}

.img-container {
  height: calc(100vw * 9 / 16);
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .van-image {
    height: calc(100vw * 9 / 16);
    z-index: 1;
  }
}

.mosaic {
  position: relative;
  overflow: hidden;
}

.mosaic:after {
  --lineStart: 4%;
  --lineEnd: 96%;
  --bgStart: 25%;
  --bgEnd: 75%;
  --color: #e9e9e9;
  content: '';
  position: absolute;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  transform: rotate(45deg);
  background-size: 15px 15px;
  background-image: /* 前两个渐变是为了防止 rotate 之后的分割线 */ linear-gradient(0deg, var(--color) var(--lineStart), transparent var(--lineStart), transparent var(--lineEnd), var(--color) var(--lineEnd)),
  linear-gradient(-90deg, var(--color) var(--lineStart), transparent var(--lineStart), transparent var(--lineEnd), var(--color) var(--lineEnd)),
  linear-gradient(45deg, var(--color) var(--bgStart), transparent var(--bgStart), transparent var(--bgEnd), var(--color) var(--bgEnd)),
  linear-gradient(-45deg, var(--color) var(--bgStart), transparent var(--bgStart), transparent var(--bgEnd), var(--color) var(--bgEnd));
}

.info {
  padding: 16px;
  border-bottom: 1px solid #e9e9e9;

  .goods-title {
    font-size: 14px;
    font-weight: bolder;
  }

  .goods-desc {
    font-size: 12px;
    color: #999;
    margin-top: 8px;
  }
}

.goods-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  .goods-tab-item {
    margin: 16px;
    font-size: 12px;
    color: #999;

    &.active {
      color: #151D36;
    }
  }
}

.goods-tab-holder {
  height: 50px;
  display: none;
}

.tab-fixed {
  .goods-tab {
    position: fixed;
    top: 39px;
    left: 0;
    right: 0;
    z-index: 1;
    background: #fff;
  }

  .goods-tab-holder {
    display: block;
  }
}

.red {
  color: #ee4a50;
}

.pay-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 50px;
  padding: 0 16px;
  border-top: 1px solid #e9e9e9;
}

.buy-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 100px;
  color: #fff;
  background: #ee4a50;
  border-radius: 100px;
  font-size: 14px;
}


.lecture-list {
  padding: 32px 32px 0 32px;
}

.lecture-item {
  padding: 16px 0;
  align-items: center;
  display: flex;

  a {
    color: #444;
    font-size: 14px;

    &:focus, &:visited, &:active {
      color: #444;
    }
  }
}

.lecture-title {
  display: flex;
  align-items: center;
}

.lecture-desc {
  font-size: 12px;
  color: #999;
  margin-left: 16px;
}

</style>
