import {v2Request} from "./v2Request";


export const orderV2Api = {

  // 下订单
  async createOrder(model) {
    return v2Request.post(`order/createOrder`, model)
  },

  // 根据单号查询
  async findByOrderNo(orderNo) {
    return v2Request.get(`order/findByOrderNo`, {params: {orderNo}})
  },

  // 发起支付
  startPay(form) {
    return v2Request.post(`order/startPay`, form);
  },

  // 搜索
  searchMy(query) {
    query = {...query};
    for (const key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }
    return v2Request.get(`order/searchMy`, {params: query})
  },

  // 完成订单
  complete(orderNo) {
    return v2Request.post(`order/complete`, {orderNo});
  },


  findHasBuyGoods(goodsId) {
    return v2Request.get(`order/findHasBuyGoods`, {params: {goodsId}})
  }

}
