import {v2Request} from "./v2Request";


export const goodsV2Api = {

  async search(query) {
    return v2Request.get(`goods/search`, {params: query})
  },

  async findById(id) {
    return v2Request.get(`goods/findById`, {params: {id}})
  }

}
